<template>
    <v-container fluid>
        <TheTitle :text="title" :icon="pageIcon">
            <v-row justify="center">
                <v-col md="8" sm="10" cols="10">
                    <v-text-field placeholder="Procurar" dense solo background-color="#FFFFFF" hide-details
                        v-model="search"></v-text-field>
                </v-col>
                <v-col md="4" sm="4" cols="4">
                    <v-btn block color="amber darken-4" dark @click="dialogSave()">
                        <v-icon small class="mr-2">mdi-plus</v-icon> Adicionar
                    </v-btn>
                    <v-btn class="mt-3" block color="success" dark @click="$router.back()">
                        <v-icon small class="mr-2">mdi-arrow-left</v-icon> Voltar
                    </v-btn>
                </v-col>
            </v-row>
        </TheTitle>

        <v-card class="pa-5">
            <v-row>
                <v-col md="2" sm="12" cols="12">
                    <b>COD</b>
                    <v-text-field 
                        dense 
                        tile 
                        outlined 
                        disabled 
                        v-model="parentData.id" 
                        class="my-2"
                    ></v-text-field>
                </v-col>
                <v-col md="7" sm="12" cols="12">
                    <b>Nome</b>
                    <v-text-field 
                        dense 
                        tile 
                        outlined 
                        disabled 
                        v-model="parentData.name" 
                        class="my-2"
                    ></v-text-field>
                </v-col>
                <v-col md="3" sm="12" cols="12">
                    <b>Setor</b>
                    <v-text-field 
                        dense 
                        tile 
                        outlined 
                        disabled
                        :value="getSectorName(parentData.idSector)"
                        class="my-2"
                    ></v-text-field>
                </v-col>
            </v-row>            
        </v-card>

        <v-card class="pa-5 mt-5 full-height">
            <v-data-table 
                :headers="header" 
                :items="dados.list" 
                dense 
                locale="pt-BR" 
                :search="search"
                :items-per-page="10"
            >
                <template v-slot:item.type="{ item }">
                    {{ getType(item.type) }}
                </template>
                <template v-slot:item.idPosition="{ item }">
                    {{ getPositionName(item.idPosition) }}
                </template>
                <template v-slot:item.consent="{ item }">
                    {{ getConsent(item.consent) }}
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <TheToolTip label="Editar">
                        <v-btn icon small tile>
                            <v-icon @click="dialogUpdate(item)">mdi-pen</v-icon>
                        </v-btn>
                    </TheToolTip>
                    <TheToolTip label="Remover">
                        <v-btn icon small tile>
                            <v-icon @click="remove(item.id)">mdi-minus</v-icon>
                        </v-btn>
                    </TheToolTip>
                </template>
            </v-data-table>
        </v-card>

        <!-- MODEL -->
        <TheDialog v-model="dialog" :title="title" :icon="pageIcon" width="1200px">
            <template>
                <v-form v-model="valid" ref="form1">
                    <v-row>
                        <v-col md="3" sm="12" cols="12">
                            <b>Tipo<sup>*</sup></b>
                            <v-select 
                                :items="type" 
                                placeholder="Nome" 
                                v-model="item.type" 
                                dense 
                                outlined 
                                class="mt-2"
                                clearable 
                                :rules="[requiredRule]"
                            ></v-select>
                        </v-col>
                        <v-col md="3" sm="12" cols="12">
                            <b> Grupo de Dados<sup>*</sup> </b>
                            <v-select 
                                :items="datagroup" 
                                placeholder="Selecione..." 
                                item-text="name" 
                                item-value="id"
                                v-model="item.idDataGroup" 
                                @change="loadDatagroupData(item)" 
                                dense 
                                outlined 
                                class="mt-2"
                                clearable 
                                :rules="[requiredRule]"
                            ></v-select>
                        </v-col>
                        <v-col md="6" sm="12" cols="12">
                            <b> Dados <sup>*</sup></b>
                            <v-select 
                                :items="data" 
                                placeholder="Selecione..." 
                                item-text="name" 
                                item-value="id"
                                v-model="item.dataList" 
                                :loading="loadingData" 
                                multiple 
                                dense 
                                outlined 
                                class="mt-2"
                                clearable 
                                :rules="[requiredRule]"
                            ></v-select>
                        </v-col>
                    </v-row> 
                    <v-row>
                        <v-col md="3" sm="12" cols="12">
                            <b> Local </b>
                            <v-select 
                                :items="place" 
                                placeholder="Selecione..." 
                                item-text="name" 
                                item-value="id"
                                v-model="item.idPlace" 
                                dense hide-details 
                                outlined 
                                class="mt-2" 
                                clearable
                            ></v-select>
                        </v-col>
                        <v-col md="3" sm="12" cols="12">
                            <b> Base Legal </b>
                            <v-select 
                                :items="legal" 
                                placeholder="Selecione..." 
                                v-model="item.legal" 
                                dense 
                                hide-details
                                outlined 
                                class="mt-2" 
                                clearable
                            ></v-select>
                        </v-col>
                        <v-col md="3" sm="12" cols="12">
                            <b> Consentimento </b>
                            <v-select 
                                :items="consent" 
                                placeholder="Selecione..." 
                                v-model="item.consent" 
                                dense 
                                hide-details
                                outlined 
                                class="mt-2" 
                                clearable
                            ></v-select>
                        </v-col>
                        <v-col md="3" sm="12" cols="12">
                            <b> Responsável </b>
                            <v-select 
                                :items="position" 
                                placeholder="Selecione..." 
                                v-model="item.idPosition" 
                                dense
                                hide-details 
                                outlined 
                                item-text="name" 
                                item-value="id" 
                                class="mt-2" 
                                clearable
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <b> Descrição </b>
                            <VueEditor 
                                v-model="item.description" 
                                :editor-toolbar="customToolbar" 
                                
                            />
                        </v-col>
                    </v-row>
                    <v-btn 
                        color="red" 
                        class="mt-2 mr-2" 
                        dark @click="dialogSave2()"
                    >
                        <v-icon small class="mr-2">mdi-alert</v-icon>
                        <span>Inserir Vulnerabilidade</span>
                    </v-btn>
                </v-form>
            </template>
            <template v-slot:actions="" class="mr-2">
                <v-btn small depressed color="primary" @click="resolveForm()">
                    <span v-if="!edit"><v-icon small class="mr-2">mdi-plus</v-icon>Adicionar</span>
                    <span v-if="edit"><v-icon small class="mr-2">mdi mdi-update</v-icon>Atualizar </span>
                </v-btn>
                <v-spacer></v-spacer>
                <span class="ml-5 caption"><sup>*</sup>Campos obrigatórios</span>
            </template>
        </TheDialog>

        <!-- VULNERABILIDADE -->
        <TheDialog v-model="dialog2" :title="title" :icon="pageIcon" width="800px">
            <template>
                <v-form v-model="valid2" ref="form2">
                    <v-row>
                        <v-col md="8" sm="12" cols="12">
                            <b> Nome <sup>*</sup></b>
                            <v-text-field 
                                placeholder="Nome" 
                                v-model="object2.name" 
                                class="my-2" 
                                dense 
                                outlined
                                color="primary" 
                                :rules="[requiredRule, spaceRule]"
                            ></v-text-field>
                        </v-col>
                        <v-col md="4" sm="12" cols="12">
                            <b> Setor </b>
                            <v-select 
                                :items="sector" 
                                item-value="id" 
                                item-text="name" 
                                v-model="object2.sectorList"
                                class="my-2" 
                                dense 
                                hide-details 
                                outlined 
                                clearable 
                                multiple
                            >
                                <template v-slot:selection="{ item, index }">
                                    <v-chip v-if="index === 0" color="primary" x-small>
                                        <span>{{ item.name }}</span>
                                    </v-chip>
                                    <sup v-if="index === 1" class="grey--text text-caption">
                                        (+{{ object2.sectorList.length - 1 }})
                                    </sup>
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col md="4" sm="12" cols="12">
                            <b> Probabilidade de Ocorrência </b>
                            <v-select 
                                :items="probability" 
                                v-model="object2.idProbability" 
                                class="my-2" 
                                dense 
                                hide-details
                                outlined 
                                clearable
                            ></v-select>
                        </v-col>

                        <v-col md="4" sm="12" cols="12">
                            <b> Impacto </b>
                            <v-select 
                                :items="impact" 
                                v-model="object2.idImpact" 
                                class="my-2" 
                                dense 
                                hide-details 
                                outlined
                                clearable
                            ></v-select>
                        </v-col>
                        <v-col md="4" sm="12" cols="12">
                            <b> Risco </b>
                            <v-text-field 
                                v-model="riskLevelText" 
                                disabled 
                                class="my-2" 
                                dense 
                                hide-details 
                                outlined
                                color="primary"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <b> Descrição </b>
                            <VueEditor 
                                v-model="object.description" 
                                :editor-toolbar="customToolbar" 
                                class="mt-2" 
                            />
                        </v-col>
                    </v-row>
                </v-form>
            </template>
            <template v-slot:actions="">
                <v-btn 
                    small 
                    depressed 
                    color="primary" 
                    class="mt-2" 
                    dark 
                    @click="resolveAdd()"
                >
                    <v-icon small class="mr-2">mdi-plus</v-icon>Adicionar
                </v-btn>
                <v-spacer></v-spacer>
                <span class="ml-5 caption"><sup>*</sup>Campos obrigatórios</span>
            </template>
        </TheDialog>
    </v-container>
</template>


<script>
import TheTitle from "@/components/TheTitle"
import TheDialog from "@/components/TheDialog"
import TheToolTip from "@/components/TheToolTip"
import { baseApiUrl, showError } from "@/global"
import axios from "axios"
import { VueEditor } from 'vue2-editor'

export default {

    components: {
        TheTitle,
        TheDialog,
        TheToolTip,
        VueEditor,
    },

    computed: {
        getPositionName() {
            return function(idPosition) {
                const position = this.position.find((el) => el.id === idPosition);
                if (position) {
                    return position.name;
                } else {
                    return "";
                }
            };
        },
        getSectorName() {
            return function(idSector) {
                const sector = this.sector.find((el) => el.id === idSector);
                if (sector) {
                    return sector.name;
                } else {
                    return "";
                }
            };
        },
        riskLevel() {
            if (!this.object2 || !this.object2.idProbability || !this.object2.idImpact) {
                return null
            }
            return this.object2.idProbability * this.object2.idImpact
        },
        riskLevelText() {
            if (!this.riskLevel) {
                return null
            }
            if (this.riskLevel <= 0.12) {
                return 'Risco Baixo'
            }
            if (this.riskLevel > 0.12 && this.riskLevel <= 0.32) {
                return 'Risco Médio'
            }
            return 'Risco Alto'
        },
    },

    data: () => ({
        
        baseRoute: 'activity',
        baseRoute2: 'vulnerability',
        
        title: 'Etapas do processo',
        pageIcon: 'mdi-run-fast',

        dados: {},
        parentData: {},
        object: {},
        object2: {},
        
        item: [],
        place: [],
        datagroup: [],
        position: [],
        sector: [],
        data: [],

        loadingData: false,
        dialog: false,
        dialog2: false,
        search: null,
        valid: false,
        valid2: false,        
        error: false,
        edit: false,

        header: [
            { text: 'Tipo', value: 'type' },
            { text: 'Dados', value: 'dataname' },
            { text: 'Local', value: 'placeName' },
            { text: 'Base Legal', value: 'stepLegal' },
            { text: 'Consentimento', value: 'consent' },
            { text: 'Responsável', value: 'idPosition' },
            { text: 'Ações', value: 'actions', width: '9%' },
        ],

        headerTurno: [
            { text: 'Grupo de Dados', value: 'idDataGroup', width: '14%' },
            { text: 'Cargo', value: 'idPosition', width: '14%' },
            { text: 'Local', value: 'idPlace', width: '14%' },
            { text: 'Coleta', value: 'collect', width: '14%' },
            { text: 'Finalidade', value: 'name', width: '14%' },
            { text: 'Compartilhamento', value: 'share', width: '14%' },
            { text: 'Descarte', value: 'discard' },
        ],

        customToolbar: [
            ['bold', 'italic', 'underline'],
            [{ list: 'ordered' }, { list: 'bullet' }],
        ],        

        legal: [
            { text: 'Consentimento do titular', value: '1' },
            { text: 'Cumprimento de obrigação legal ou regulatória', value: '2' },
            { text: 'Execução, pela administração pública, de políticas públicas', value: '3' },
            { text: 'Realização de estudos por órgão de pesquisa', value: '4' },
            { text: 'Execução de contrato', value: '5' },
            { text: 'Diligências pré-contratuais', value: '6' },
            { text: 'Exercício regular de direitos', value: '7' },
            { text: 'Proteção da vida e da incolumidade física', value: '8' },
            { text: 'Tutela da saúde', value: '9' },
            { text: 'Legítimo Interesse', value: '10' },
            { text: 'Proteção de crédito', value: '11' },
            { text: 'Prevenção à fraude em sistemas eletrônicos', value: '12' },
            { text: 'Segurança do titular em sistemas eletrônicos', value: '13' },
            { text: 'Não atendida', value: '14' },
        ],

        type: [
            { text: 'Coleta', value: '1' },
            { text: 'Uso/Finalidade', value: '2' },
            { text: 'Compartilhamento', value: '3' },
            { text: 'Retenção/Armazenamento', value: '4' },
            { text: 'Descarte', value: '5' },
        ],

        consent: [
            { text: 'De usuário', value: '1' },
            { text: 'Presumido', value: '2' },
            { text: 'Informado', value: '3' },
            { text: 'Ativo', value: '4' },
        ],
       
        risk: [
            { text: 'Baixo', value: 1 },
            { text: 'Médio', value: 2 },
            { text: 'Alto', value: 3 },
        ],

        probability: [
            { text: 'Quase certo', value: 1 },
            { text: 'Alta', value: 0.8 },
            { text: 'Média', value: 0.6 },
            { text: 'Baixa', value: 0.4 },
            { text: 'Rara', value: 0.2 },
        ],

        impact: [
            { text: 'Gravíssimo', value: 0.8 },
            { text: 'Grave', value: 0.6 },
            { text: 'Médio', value: 0.4 },
            { text: 'Leve', value: 0.2 },
            { text: 'Sem Impacto', value: 0.05 },
        ],

    }),

    methods: {

        loadItem() {
            const id = this.$route.params.id

            this.$store.dispatch("setLoadingInfo", true)
            const url = `${baseApiUrl}/${this.baseRoute}/${id}`
            axios
                .get(url)
                .then((res) => {
                    this.$store.dispatch("setLoadingInfo", false)
                    this.parentData = res.data
                })
                .catch(showError)

                this.getById(id)
        },

        loadData() {
            this.$store.dispatch("setLoadingInfo", true)
            const url = `${baseApiUrl}/activity/search/${this.$route.params.id}/step-activity`
            
            axios
                .get(url)
                .then((res) => {
                    this.$store.dispatch('setLoadingInfo', false)
                    this.dados = res.data
                })
                .catch(showError)
        },
        
        loadDatagroup() {
            const url = `${baseApiUrl}/datagroup`
            axios
                .get(url)
                .then((res) => {
                    this.datagroup = res.data
                })
                .catch(showError)
        },

        loadPlace() {
            const url = `${baseApiUrl}/place`
            axios
                .get(url)
                .then((res) => {
                    this.place = res.data
                })
                .catch(showError)
        },

        loadPosition() {
            const url = `${baseApiUrl}/position`
            axios
                .get(url)
                .then((res) => {
                    this.position = res.data
                })
                .catch(showError)
        },

        loadSector() {
            const url = `${baseApiUrl}/sector`
            axios
                .get(url)
                .then((res) => {
                    this.sector = res.data
                })
                .catch(showError)
        },

        loadDatagroupData(item) {
            if (!item.idDataGroup) {
                return false
            }
            this.data = []
            this.loadingData = true
            const url = `${baseApiUrl}/datagroup/${item.idDataGroup}`
            axios
                .get(url)
                .then((res) => {
                    this.data = res.data.list
                    this.loadingData = false
                })
                .catch(showError)
        },

        async loadPurposeData(item) {
            const url = `${baseApiUrl}/${this.baseRoute}/purpose/${item.id}`
            item.dataList = await axios
                .get(url)
                .then((res) => {
                    return res.data
                })
                .catch(showError)
        },

        getType(id) {
            for (let i = 0; i < this.type.length; i++) {
                const element = this.type[i]
                if (element.value === id) {
                    return element.text
                }
            }
        },
        
        getConsent(id) {
            for (let i = 0; i < this.consent.length; i++) {
                const element = this.consent[i]
                if (element.value === id) {
                    return element.text
                }
            }
        },
        
        getRisk(risk) {
            if (!risk) {
                return ''
            }
            if (risk <= 0.12) {
                return 'Risco Baixo'
            }
            if (risk > 0.12 && risk <= 0.32) {
                return 'Risco Médio'
            }
            return 'Risco Alto'
        },
        
        getProbability(id) {
            for (let i = 0; i < this.probability.length; i++) {
                const element = this.probability[i]
                if (element.value === id) {
                    return element.text
                }
            }
            return ''
        },
        
        getImpact(id) {
            for (let i = 0; i < this.impact.length; i++) {
                const element = this.impact[i]
                if (element.value === id) {
                    return element.text
                }
            }
            return ''
        },

        getDataGroupName(id) {
            for (let i = 0; i < this.datagroup.length; i++) {
                const element = this.datagroup[i]
                if (element.id === id) {
                    return element.name
                }
            }
            return ''
        },

        async getById(id) {
            const url = `${baseApiUrl}/${this.baseRoute}/${id}`
            this.$store.dispatch('setLoadingInfo', true)
            return await axios
                .get(url)
                .then((res) => {
                this.$store.dispatch('setLoadingInfo', false)
                return res.data
                })
                .catch(() => {
                this.$store.dispatch('setLoadingInfo', false)
                return []
                })
        },
        
        dialogSave() {
            this.item = {}
            this.edit = false
            this.dialog = true
        },

        dialogSave2() {
            this.object2 = {}
            this.dialog2 = true
        },

        resolveAdd() {
            const form2Inputs = this.$refs.form2.$refs
            for (const inputName in form2Inputs) {
                form2Inputs[inputName].validate()
                if (!form2Inputs[inputName].valid) {
                    this.$store.dispatch('setErrorInfo', {
                        open: true,
                        text: 'Os campos não foram preenchidos corretamente, você pode verificar?',
                        button: 'Ok!',
                    })
                    return
                }
            }
            if (this.dialog2) {
                this.save2()
            }
        },

        resolveForm() {
            this.$refs.form1.validate()
            if (!this.valid) {
                this.$store.dispatch('setErrorInfo', {
                    open: true,
                    text: 'Os campos não foram preenchidos corretamente, você pode verificar?',
                    button: 'Ok!',
                })
                return
            }
            if (this.edit) {
                this.update()
            } else {
                this.save()
            }
        },
        
        save() {
            this.error = false
            const url = `${baseApiUrl}/${this.baseRoute}/son`

            const objects = this.item
            objects.idActivity = this.parentData.id

            axios
                .post(url, objects)
                .then(() => this.loadData())
                .catch(showError)
                .finally(() => {
                    this.dialog = false
                }) 
        },

        save2() {
            this.error = false
            const url = `${baseApiUrl}/${this.baseRoute2}`
            const objects = this.object2
            this.object2.risk = this.riskLevel

            axios
                .post(url, objects)
                .then(() => {
                    this.dialog2 = false
                })
                .catch(showError)
        },

        async dialogUpdate(item) {
            this.item = await this.getById(item.id)
            await this.expand(item)
            this.item = item
            this.edit = true
            this.dialog = true
        },

        async expand(item) {
            item.expanded = !item.expanded
            if (item.expanded === true) {
                await this.loadPurposeData(item)
                await this.loadDatagroupData(item)
            }
        },

        update() {
            this.error = false
            const id = this.item.id

            const url = `${baseApiUrl}/activity/updateSon/${id}`
            const objects = this.item
            objects.idActivity = this.parentData.id

            delete objects.sectorName
            delete objects.createdAt
            delete objects.updatedAt
            delete objects.deletedAt

            axios
                .put(url, objects)
                .then(() => this.loadData())
                .catch(showError)
                .finally(() => {
                    this.dialog = false
                })
        },

        remove(id) {
            if (confirm('Remove?')) {
                const url = `${baseApiUrl}/${this.baseRoute}/search/${id}/step-activity`
                axios
                    .delete(url)
                    .then(() => this.loadData())
                    .catch(showError)
            }
        }, 
    },

    mounted() {
        this.loadData()
        this.loadSector()
        this.loadDatagroup()
        this.loadPlace()
        this.loadPosition()
        this.loadItem()
    },
}
</script>

<style>
    .full-height {
        height: 50vh;
    }
</style>